import _ from 'lodash';
import formService from '@/services/formService';
import moment from 'moment';
import Vue from 'vue';
import GRN from '../../model/grn.js';
import GRNGap from '../../model/grnGap.js';
const state = {
  baseUrl: '/form/operations',
  formList: [],
  form: null,
  loading: false,
  formData: {
    pagination: null,
    columnNames: [],
    rows: []
  },
  formDataDownload: {
    pagination: null,
    columnNames: [],
    rows: []
  },
  inputFields: null,
  data: [],
  grnReportData: [],
  showArchived: null,
  grnGapReportData: [],
  reportDownloading: false
};

let dateField;
let timeField;
let dateFieldModel;
let timeFieldModel;

const actions = {
  getInputFields({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    // commit('setInputFields', { inputFields: null });

    formService
      .getInputFields({ id })
      .then(response => {
        commit('setInputFields', { inputFields: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { type = 'form', id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    formService
      .deleteOne({ type, id })
      .then(_response => {
        commit('deleteForm', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: type === 'form' ? 'Form has been archived.' : 'Staff has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteFullForm({ dispatch, commit }, { type = 'form', id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    formService
      .deleteFullForm({ type, id })
      .then(_response => {
        commit('deleteFormFull', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: type === 'form' ? 'Form has been deleted.' : ' Staff has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          {
            e,
            router
          },
          { root: true }
        );
      });
  },
  postOne({ dispatch, commit }, { type = 'form', form, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    formService
      .postOne({ type, form })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: type === 'form' ? 'New form has been created.' : 'New staff has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  saveFormData({ dispatch, commit }, { type = 'form', formData, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    formService
      .saveFormData({ type, formData })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: type === 'form' ? 'New form has been created.' : 'New staff has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listUnarchivedForms({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setUnarchivedForms', { formList: [] });

    formService
      .listForms({})
      .then(response => {
        commit('setUnarchivedForms', { formList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  listArchivedForms({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setArchivedForms', { formList: [] });
    formService
      .listForms({})
      .then(response => {
        commit('setArchivedForms', { formList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  listFormData({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setFormData', { data: { rows: [], columns: [], pagination: null } });
    commit('startRequest');

    formService
      .listFormData({ query })
      .then(response => {
        commit('setFormData', { data: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  async downloadFormData({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadFormData', { data: { rows: [], columns: [], pagination: null } });

    let stopDownload = false;
    const pageSize = 9999;
    const accData = [];

    for (let i = 0; !stopDownload; ) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await formService.listFormData({
          query: { ...query, page: i + 1, page_size: pageSize }
        });
        if (response.data.pagination.total_rows > 9999) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: 'Preparing your download, please wait...'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch (e) {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
        return;
      }
    }
    commit('setDownloadFormData', {
      data: { rows: accData, columns: [], pagination: null }
    });
  },
  upload({ dispatch, commit }, { uploadData, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    formService
      .upload(uploadData)
      .then(_response => {
        commit('finishRequest');
        if (_response.data.length === 0) {
          Vue.swal({
            title: 'Validation Error',
            text: 'Please make sure that there is no empty field in upload csv.',
            type: 'error',
            confirmButtonText: 'OK'
          });
        } else {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: '',
              type: 'success',
              text: 'User(s) uploaded successfully !!'
            },
            { root: true }
          );
        }

        router.go();
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: 'Upload failed, remember to use only sample file provided and check the line number !!'
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e }, { root: true });
      });
    commit('finishRequest');
  },

  async getGrnReportData({ dispatch, commit }, { query, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setGrnReportData', { grnReportData: [] });
    formService
      .getGrnData({ query })
      .then(response => {
        commit('setGrnReportData', {
          grnReportData: response.data
        });
      })
      .catch(e => {
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
      });
  },
  async getGrnGapReportData({ dispatch, commit }, { query, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('startDownloading');
    commit('setGrnGapReportData', {
      data: { grnReportData: [], formData: [] }
    });

    try {
      const grnReportPromise = formService.getGrnData({ query }).then(response => response.data.rows);
      const fetchFormData = async () => {
        let stopDownload = false;
        const pageSize = 9999;
        const accData = [];
        let i = 0;

        while (!stopDownload) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const response = await formService.listFormData({
              query: { ...query, page: i + 1, page_size: pageSize }
            });

            if (response.data.pagination.total_rows > 9999) {
              dispatch(
                'alert/success',
                {
                  showType: 'toast',
                  position: 'center',
                  title: 'Preparing your download, please wait...'
                },
                { root: true }
              );
            }

            if (response.data.rows.length === 0) {
              stopDownload = true;
            }

            accData.push(...response.data.rows);
            i += 1;
          } catch (e) {
            commit('requestFailed');
            dispatch('common/handleServiceException', { e, router }, { root: true });
            return null; // Returning null so that we can handle failure properly
          }
        }
        return accData;
      };

      const formDataPromise = fetchFormData();

      // Wait for both requests to complete
      const [grnReportData, formData] = await Promise.all([grnReportPromise, formDataPromise]);

      if (!grnReportData || !formData) {
        throw new Error('Failed to fetch one of the datasets');
      }

      // Commit the combined data
      commit('setGrnGapReportData', {
        data: { grnReportData, formData }
      });
    } catch (e) {
      commit('requestFailed');
      dispatch('common/handleServiceException', { e, router }, { root: true });
    }
  }
};

const getters = {};

const mutations = {
  deleteForm(state, { id }) {
    /* eslint no-underscore-dangle: 0 */
    state.formList = state.formList.filter(i => i._id !== id);
    state.loading = false;
  },
  deleteFormFull(state, { id }) {
    /*eslint no-underscore-dangle: 0 */
    state.formList = state.formList.filter(i => i._id !== id);
    state.loading = false;
  },
  startRequest(state) {
    state.loading = true;
  },
  startDownloading(state) {
    state.reportDownloading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setUnarchivedForms(state, { formList }) {
    if (formList.length) {
      state.formList = formList.filter(form => form.archived === false);
    }
    state.loading = false;
    state.showArchived = false;
  },
  setArchivedForms(state, { formList }) {
    if (formList.length) {
      state.formList = formList.filter(form => form.archived === true);
    }
    state.loading = false;
    state.showArchived = true;
  },
  setInputFields(state, { inputFields }) {
    const isNull = inputFields === null;
    if (!isNull) {
      dateField = inputFields.config.map(i => i.type === 'date');
      timeField = inputFields.config.map(i => i.type === 'time');
    }
    if (
      (dateField !== undefined && dateField.includes(true)) ||
      (timeField !== undefined && timeField.includes(true))
    ) {
      dateFieldModel = inputFields.config.map(i => (i.type === 'date' ? i.model : ''));
      timeFieldModel = inputFields.config.map(i => (i.type === 'time' ? i.model : ''));
    }
    state.inputFields = inputFields;
    state.loading = false;
  },
  setGrnReportData(state, { grnReportData }) {
    state.grnReportData = _.map(grnReportData.rows, grn => {
      const newGrn = new GRN({
        articleCode: grn.articleCode,
        storeId: grn.storeId,
        grnNumber: grn.grnNumber,
        grnEntryTime: grn.grnEntryTime,
        grnEntryDate: grn.grnEntryDate,
        grnYear: grn.grnYear,
        poOrSto: grn.poOrSto
      });
      return newGrn;
    });
    state.loading = false;
  },
  setGrnGapReportData(state, { data }) {
    const { grnReportData, formData } = data;
    if (grnReportData.length && formData.length) {
      const uniqueGrnEntries = {};

      grnReportData.forEach(grn => {
        const storeId = grn.storeId;
        const grnDate = moment(grn.createdAt).format('YYYY-MM-DD'); // Extract date part

        const key = `${storeId}_${grnDate}`; // Unique key combining storeId and date

        if (!uniqueGrnEntries[key]) {
          const formattedTime = moment(grn.grnEntryTime.padStart(6, '0'), 'HHmmss').format('hh:mm A');
          uniqueGrnEntries[key] = formattedTime;
        }
      });

      // Step 2: Map through formData and assign the correct grnEntryTime & calculate GAP
      const updatedFormData = formData.map(entry => {
        const entryDate = moment(entry.createdAt).format('YYYY-MM-DD'); // Get entry date
        const key = `${entry.storeid}_${entryDate}`; // Find matching GRN entry

        const grnEntryTime = uniqueGrnEntries[key] || null;
        let gap = 'N/A';

        if (grnEntryTime && entry.vehicleEntryTime) {
          const vehicleTime = moment(entry.vehicleEntryTime).format('hh:mm A');
          const grnTime = moment(grnEntryTime, 'hh:mm A');

          const duration = moment.duration(grnTime.diff(moment(vehicleTime, 'hh:mm A')));
          gap = `${String(Math.floor(duration.asHours())).padStart(2, '0')}:${String(duration.minutes()).padStart(
            2,
            '0'
          )}:${String(duration.seconds()).padStart(2, '0')}`;
        }

        return {
          ...entry,
          grnEntryTime,
          vehicleEntryTime: moment(entry.vehicleEntryTime).format('hh:mm A'),
          grnGapTime: gap
        };
      });
      state.grnGapReportData = _.map(updatedFormData, report => {
        const newGrn = new GRNGap({
          storeId: report.storeid,
          submittedBy: report.submittedBy,
          grnEntryTime: report.grnEntryTime,
          vehicleEntryTime: report.vehicleEntryTime,
          grnGapTime: report.grnGapTime,
          createdAt: report.createdAt
        });
        return newGrn;
      });
      state.reportDownloading = false;
    }
  },

  setFormData(state, { data }) {
    if (
      (dateFieldModel !== undefined && dateFieldModel.length > 0) ||
      (timeFieldModel !== undefined && timeFieldModel.length > 0)
    ) {
      data.rows.forEach(row => {
        dateFieldModel.forEach(model => {
          if (model !== '') {
            row[model] = moment(row[model]).format('DD-MM-YYYY');
          }
        });
        timeFieldModel.forEach(model => {
          if (model !== '') {
            row[model] = moment(row[model]).format('hh:mm  A');
          }
        });
      });
    }
    const rows = data.rows.map(e => ({
      ...e,
      createdAt: moment(e.createdAt).format('DD-MM-YYYY   hh:mm a'),
      updatedAt: moment(e.updatedAt).format('LL')
    }));
    const obj = {
      rows,
      columnNames: [],
      pagination: data.pagination
    };
    if (data) {
      if (data.rows && data.rows[0]) {
        const { __v, _id, ...rest } = data.rows[0]; //eslint-disable-line
        obj.columnNames = Object.keys(rest);
      }
    }
    state.formData = obj;
    state.loading = false;
  },

  setDownloadFormData(state, { data }) {
    const rows = data.rows.map(e => ({
      ...e,
      createdAt: moment(e.createdAt).format('DD-MM-YYYY  hh:mm: a')
      // updatedAt: moment(e.updatedAt).format('LL')
    }));
    const obj = {
      rows,
      columnNames: [],
      pagination: data.pagination
    };
    if (data) {
      if (data.rows && data.rows[0]) {
        const { __v, _id, ...rest } = data.rows[0]; //eslint-disable-line
        obj.columnNames = Object.keys(rest);
      }
    }
    state.formDataDownload = obj;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
