import store from '@/store';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL || '/',
  mode: 'history',
  routes: [
    {
      path: '/checklist_report',
      name: 'report',
      component: () => import(/* webpackChunkName: "setting" */ '../views/Report/ReportList'),
      //import(/* webpackChunkName: "Checklist" */ '../views/ChecklistV2_Fill/ReportListV2.vue');
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/',
      name: 'dashboard',
      // component: () => import(/* webpackChunkName: "analytics" */ '../views/Dashboard/Dashboard.vue'),
      component: () => import(/* webpackChunkName: "analytics" */ '../views/Dashboard/DashBoard2.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
      meta: {
        layout: 'simple-layout'
      }
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import(/* webpackChunkName: "login" */ '../views/ChangePassword.vue'),
      meta: {
        layout: 'simple-layout'
      }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "login" */ '../views/ResetPassword.vue'),
      meta: {
        layout: 'simple-layout'
      }
    },
    // {
    //   path: '/todo',
    //   name: 'todo',
    //   component: () => import(/* webpackChunkName: "todo" */ '../views/Todo/Todo.vue'),
    //   meta: {
    //     layout: 'backend-layout',
    //     requiresAuth: true
    //   }
    // },

    {
      path: '/logout',
      name: 'logout',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "login" */ '../views/Logout.vue'),
      meta: {
        layout: 'simple-layout'
      }
    },
    {
      path: '/user',
      name: 'user',
      component: () => import(/* webpackChunkName: "user" */ '../views/User/UserList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },

    {
      path: '/permissions',
      name: 'permissions',
      component: () => import(/* webpackChunkName: "user" */ '../views/Permissions/PermissionsList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/comments',
      name: 'comments',
      component: () => import(/* webpackChunkName: "Comment" */ '../views/Comments/commentList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/eventplanlist',
      name: 'eventplanlist',
      component: () => import(/* webpackChunkName: "Event plan" */ '../views/Events/EventPlanList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/event-plan/new',
      name: 'event-plan-new',
      component: () => import(/* webpackChunkName: "CheckListForm" */ '../views/Events/EventForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/photo-sharing-event',
      name: 'events',
      component: () => import(/* webpackChunkName: "Events" */ '../views/Events/EventsList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/photo-sharing-event/:id',
      name: 'events-view',
      component: () => import(/* webpackChunkName: "Events" */ '../views/Events/EventsView.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/event-plan/:id',
      name: 'event-plan-view',
      component: () => import(/* webpackChunkName: "Events" */ '../views/Events/EventForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/active-event-list',
      name: 'active-event-list',
      component: () => import(/* webpackChunkName: "Events" */ '../views/Events/ActiveEvent.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/permissions/new',
      name: 'permissions-new',
      component: () => import(/* webpackChunkName: "user" */ '../views/Permissions/PermissionsForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/permissions/:id',
      name: 'permissions-edit',
      component: () => import(/* webpackChunkName: "user" */ '../views/Permissions/PermissionsForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },

    {
      path: '/checklist',
      name: 'checklist',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/Checklist/Checklist.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },

    {
      path: '/checklist/new',
      name: 'checklist-new',
      component: () => import(/* webpackChunkName: "CheckListForm" */ '../views/Checklist/CheckListForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/checklist/:id',
      name: 'checklist-edit',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/Checklist/CheckListForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/checklist-fill/:id',
      name: 'checklist-fill',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/ChecklistFill/ChecklistFill.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },

    {
      path: '/draft-checklists',
      name: 'draft-checklists',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/ChecklistFill/DraftedChecklist.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/checklist-fill',
      name: 'fill-checklist',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/ChecklistFill/FillChecklist.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/checklistV2',
      name: 'checklistV2',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/ChecklistV2/ChecklistV2.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/checklistV2/new',
      name: 'checklistV2-new',
      component: () => import(/* webpackChunkName: "CheckListForm" */ '../views/ChecklistV2/CheckListV2Form.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/checklistV2/:id',
      name: 'checklistV2-edit',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/ChecklistV2/CheckListV2Form.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/checklistV2_Fill',
      name: 'checklist_v2',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/ChecklistV2_Fill/Index.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/checklistV2_Fill/:id',
      name: 'checklistV2_Fill-edit',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/ChecklistV2_Fill/FillForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/reportFormV2/:id',
      name: 'checklistV2_Fill',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/ChecklistV2_Fill/ReportFormV2.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/draft-checklistsV2',
      name: 'draft-checklistsV2',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/ChecklistV2_Fill/DraftChecklistV2.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/foodSafety_Reports',
      name: 'fodSafety_Reports',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/ChecklistV2_Fill/ReportListV2.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/Feedback/FeedbackList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/feedback/new',
      name: 'feedback-new',
      component: () => import(/* webpackChunkName: "FeedbackForm" */ '../views/Feedback/FeedbackForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/feedbackList/:id',
      name: 'feedbackList-edit',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/Feedback/FeedbackForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash',
      name: 'petty-cash',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/PettyCash/PettyCash.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash/expense/new',
      name: 'petty-cash-expense-new',
      component: () =>
        import(/* webpackChunkName: "checklist" */ '../views/PettyCash/Expense/PettyCashExpenseForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash/expense',
      name: 'petty-cash-expense',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/PettyCash/Expense/ExpenseList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash/expense/:id',
      name: 'petty-cash-expense-edit',
      component: () =>
        import(/* webpackChunkName: "checklist" */ '../views/PettyCash/Expense/PettyCashExpenseForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash/budget/new',
      name: 'petty-cash-budget-new',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/PettyCash/Budget/PettyCashBudgetForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash/budget',
      name: 'petty-cash-budget',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/PettyCash/Budget/BudgetList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash/BudgetSummary',
      name: 'petty-cash-BudgetSummary',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/PettyCash/Budget/BudgetSummary.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash/budgetIncrease-request',
      name: 'petty-cash-budgetIncrease-request',
      component: () =>
        import(/* webpackChunkName: "checklist" */ '../views/PettyCash/BudgetIncrease/BudgetIncrease.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash/account',
      name: 'petty-cash-account',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/PettyCash/CashInHand/CashInHandList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash/account/new',
      name: 'petty-cash-account-new',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/PettyCash/CashInHand/CashInHandForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash/account/:id',
      name: 'petty-cash-account-edit',
      component: () => import(/* webpackChunkName: "checklist" */ '../components/PettyCash/AccountBox.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/petty-cash/budget/:id',
      name: 'petty-cash-budget-edit',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/PettyCash/Budget/PettyCashBudgetForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },

    {
      path: '/mis/',
      name: 'mis',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/MIS/Mis.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/bulk',
      name: 'bulk',
      component: () => import(/* webpackChunkName: "checklist" */ '../views/Bulk/BulkList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },

    {
      path: '/promo-code',
      name: 'promo-code',
      component: () => import('../views/promoCode/promoCodeList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/promo-code/new',
      name: 'promo-code-new',
      component: () => import('../views/promoCode/PromoCodeForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/promo-code/:id',
      name: 'promo-code-edit',
      component: () => import('../views/promoCode/PromoCodeForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },

    {
      path: '/bulk/new',
      name: 'bulk-new',
      component: () => import(/* webpackChunkName: "FeedbackForm" */ '../views/Bulk/BulkList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/bulkList/:id',
      name: 'bulkList-edit',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/Bulk/BulkList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },

    {
      path: '/user/new',
      name: 'user-new',
      component: () => import(/* webpackChunkName: "user" */ '../views/User/UserForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/user/:id',
      name: 'user-edit',
      component: () => import(/* webpackChunkName: "user" */ '../views/User/UserForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/stores',
      name: 'stores',
      component: () => import(/* webpackChunkName: "user" */ '../views/Store/StoreList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/store/new',
      name: 'store-new',
      component: () => import(/* webpackChunkName: "user" */ '../views/Store/StoreForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/store/:id',
      name: 'store-edit',
      component: () => import(/* webpackChunkName: "user" */ '../views/Store/StoreForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/atf',
      name: 'atf',
      component: () => import(/* webpackChunkName: "user" */ '../views/AssetTransferForm/AssetTransferList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/atf/new',
      name: 'atf-new',
      component: () =>
        import(/* webpackChunkName: "CheckListForm" */ '../views/AssetTransferForm/AssetTransferForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/atf/:id',
      name: 'atf-edit',
      component: () => import(/* webpackChunkName: "Checklist" */ '../views/AssetTransferForm/AssetTransferForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/form',
      name: 'form',
      component: () => import(/* webpackChunkName: "user" */ '../views/Form/FormList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/pop',
      name: 'pop',
      component: () => import(/* webpackChunkName: "user" */ '../views/POP/POPList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/pop/new',
      name: 'popmanual',
      component: () => import(/* webpackChunkName: "user" */ '../views/POP/PopManualFormBox.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/form/new',
      name: 'form-new',
      component: () => import(/* webpackChunkName: "user" */ '../views/Form/NewForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/form/operations',
      name: 'form-operations',
      component: () => import(/* webpackChunkName: "user" */ '../views/Form/ListFormData.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/form/:id',
      name: 'form-edit',
      component: () => import(/* webpackChunkName: "user" */ '../views/Form/NewForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/form/fill/:id',
      name: 'form-fill',
      component: () => import(/* webpackChunkName: "user" */ '../views/Form/FormFill.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/video',
      name: 'video',
      component: () => import(/* webpackChunkName: "Video" */ '../views/Videos/VideosList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/video/new',
      name: 'video-new',
      component: () => import(/* webpackChunkName: "Video" */ '../views/Videos/VideoForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/video/redirect/:id',
      name: 'video-redirct',
      component: () => import(/* webpackChunkName: "Video" */ '../views/Videos/VideoRedirectUrl.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: false
      }
    },
    {
      path: '/video/:id',
      name: 'video-edit',
      component: () => import(/* webpackChunkName: "Video" */ '../views/Videos/VideoForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/document-share',
      name: 'document-share',
      component: () => import(/* webpackChunkName: "Document" */ '../views/DocumentUpload/DocumentsList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/document/new',
      name: 'document-new',
      component: () => import(/* webpackChunkName: "Document" */ '../views/DocumentUpload/DocumentForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/document/:id',
      name: 'document-edit',
      component: () => import(/* webpackChunkName: "Document" */ '../views/DocumentUpload/DocumentForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/cstTask',
      name: 'cst-task',
      component: () => import(/* webpackChunkName: "CST" */ '../views/CST/CSTTaskList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/cstTask1',
      name: 'cst-task1',
      component: () => import(/* webpackChunkName: "CST" */ '../views/CST/taskListStore.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/cstSubmission/new/:id',
      name: 'cst-task-submission',
      component: () => import(/* webpackChunkName: "CST" */ '../views/CST/CSTSubmissionForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/cstTask/new',
      name: 'cst-task-new',
      component: () => import(/* webpackChunkName: "CST" */ '../views/CST/CSTForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/cstTask/:id',
      name: 'cst-task-edit',
      component: () => import(/* webpackChunkName: "CST" */ '../views/CST/CSTForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/cstSubmission',
      name: 'cst-submission',
      component: () => import(/* webpackChunkName: "CST" */ '../views/CST/submissionList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/cstSubmission/cstId',
      name: 'cst-submission-cstId',
      component: () => import(/* webpackChunkName: "CST" */ '../views/CST/SubmissionListById.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/cstSubmission/attachments/:id',
      name: 'cst-submission-attachments',
      component: () => import(/* webpackChunkName: "CST" */ '../views/CST/CSTSubmitData.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/role',
      name: 'role',
      component: () => import(/* webpackChunkName: "Role" */ '../views/Role/RoleList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/role/new',
      name: 'role-new',
      component: () => import(/* webpackChunkName: "RoleForm" */ '../views/Role/RoleForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/role/:id',
      name: 'role-edit',
      component: () => import(/* webpackChunkName: "Role" */ '../views/Role/RoleForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/localBrand',
      name: 'brand',
      component: () => import(/* webpackChunkName: "Brand" */ '../views/LocalBrand/BrandList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/kyc',
      name: 'kyc',
      component: () => import(/* webpackChunkName: "Brand" */ '../views/KYC/KycList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/kycInstitutional',
      name: 'kyc-institutional',
      component: () => import(/* webpackChunkName: "Brand" */ '../views/KYC/KycInstitutionalList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/license-upload',
      name: 'license-upload',
      component: () => import(/* webpackChunkName: "Brand" */ '../views/License/LicenseList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/license/new',
      name: 'license-new',
      component: () => import(/* webpackChunkName: "Brand" */ '../views/License/LicenseForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/vendorFeedback',
      name: 'vendor-feedback',
      component: () => import(/* webpackChunkName: "Brand" */ '../views/Rating/Rating.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: false
      }
    },
    {
      path: '/vendorFeedbackReport',
      name: 'vendor-feedback-report',
      component: () => import(/* webpackChunkName: "Brand" */ '../views/Rating/RatingList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/thanks',
      name: 'Thank You',
      component: () => import(/* webpackChunkName: "Brand" */ '../views/Rating/Thanks.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: false
      }
    },
    {
      path: '/nps',
      name: 'nps',
      component: () => import(/* webpackChunkName: "setting" */ '../views/NPSInternal/NPSList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/nps/:id',
      name: 'nps-view',
      component: () => import(/* webpackChunkName: "setting" */ '../views/NPSInternal/NPSView.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },

    {
      path: '/customer-number',
      name: 'Customer Number',
      component: () => import(/* webpackChunkName: "Bulk Customer" */ '../views/BulkCustomer/CustomerList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/barcodeScan',
      name: 'barcode-scan',
      component: () => import(/* webpackChunkName: "barcode-scan" */ '../views/BacodeScanner/scannedList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/barcodeCategoryList',
      name: 'barcode-category-list',
      component: () => import(/* webpackChunkName: "category-list" */ '../views/BacodeScanner/uploadCategoryList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/tags',
      name: 'tags',
      component: () => import(/* webpackChunkName: "Role" */ '../views/Tags/TagList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/gift-distribute-app',
      name: 'gift-distribute-app',
      component: () => import(/* webpackChunkName: "Role" */ '../views/Gift/GiftList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/tags/new',
      name: 'tags-new',
      component: () => import(/* webpackChunkName: "RoleForm" */ '../views/Tags/TagForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/tags/:id',
      name: 'tags-edit',
      component: () => import(/* webpackChunkName: "Role" */ '../views/Tags/TagForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/schedule',
      name: 'schedule',
      component: () => import(/* webpackChunkName: "Schedule" */ '../views/Schedule/ScheduleList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/notes',
      name: 'notes',
      component: () => import(/* webpackChunkName: "Role" */ '../views/Notes/NoteList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/notes/new',
      name: 'notes-new',
      component: () => import(/* webpackChunkName: "RoleForm" */ '../views/Role/RoleForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    // {
    //   path: '/notes/:id',
    //   name: 'notes-edit',
    //   component: () => import(/* webpackChunkName: "Role" */ '../views/Role/RoleForm.vue'),
    //   meta: {
    //     layout: 'backend-layout',
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/notes/:id',
      name: 'notes-view',
      component: () => import(/* webpackChunkName: "notes" */ '../views/Notes/NotesView.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },

    {
      path: '/report',
      name: 'reportPage',
      component: () => import(/* webpackChunkName: "setting" */ '../views/Report/ReportList'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/report/:id',
      name: 'report-view',
      component: () => import(/* webpackChunkName: "setting" */ '../views/Report/ReportView.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/contactUs',
      name: 'contact-us',
      component: () => import(/* webpackChunkName: "barcode-scan" */ '../components/contactUs.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/complain',
      name: 'complain',
      component: () => import(/* webpackChunkName: "complain" */ '../views/contactUs/TicketListForTest.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/complain-tickets',
      name: 'complain-tickets',
      component: () => import(/* webpackChunkName: "complain" */ '../views/contactUs/TicketListForAll.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/transaction-complain',
      name: 'transaction-complain',
      component: () =>
        import(/* webpackChunkName: "complain" */ '../views/TransactionComplain/transactionComplainList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/transactioncomplain/new',
      name: 'transaction-complain-new',
      component: () =>
        import(/* webpackChunkName: "complain" */ '../views/TransactionComplain/transactionComplainForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },

    {
      path: '/customer-transaction-complaint/:id',
      name: 'complain-view',
      component: () =>
        import(/* webpackChunkName: "complain" */ '../views/TransactionComplain/transactionComplainForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/complain/:id',
      name: 'complain-view-edit',
      component: () => import(/* webpackChunkName: "complain" */ '../views/contactUs/modifyTicket.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/setting',
      name: 'setting',
      component: () => import(/* webpackChunkName: "setting" */ '../views/Setting/SettingList.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/setting/new',
      name: 'setting-new',
      component: () => import(/* webpackChunkName: "setting" */ '../views/Setting/SettingForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    },
    {
      path: '/setting/:id',
      name: 'setting-edit',
      component: () => import(/* webpackChunkName: "setting" */ '../views/Setting/SettingForm.vue'),
      meta: {
        layout: 'backend-layout',
        requiresAuth: true
      }
    }
  ]
});

router.beforeEach((to, _from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']()) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;
