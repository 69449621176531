import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async list({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'startdate', 'enddate', 'stores']);
    let url = `${configService.get('apiUrl')}/dashboard`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async mobileList({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['startdate', 'enddate', 'region', 'asm', 'storeid']);
    let url = `${configService.get('apiUrl')}/dashboard/checklist`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
