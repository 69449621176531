import moment from 'moment';

export default class GRN {
  constructor({ articleCode, grnEntryDate, grnEntryTime, grnNumber, grnYear, poOrSto, storeId }) {
    this.articleCode = articleCode || '';
    this.storeId = storeId || '';
    this.grnNumber = grnNumber || '-';
    this.grnEntryTime = moment(grnEntryTime.padStart(6, '0'), 'HHmmss').format('HH:mm:ss A') || '-';
    this.grnEntryDate = grnEntryDate ? moment(grnEntryDate).format('LL') : null;
    this.grnYear = grnYear || 'NA';
    this.poOrSto = poOrSto || '';
  }
}
