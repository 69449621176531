import _ from 'lodash';
import allAsmListService from '../../services/allAsmListService';

const state = {
  asmList: [],
  loading: false
};

const actions = {
  asmListAction({ dispatch, commit }, { router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setAsmList', { asmList: [] });

    allAsmListService
      .asmList({})
      .then(response => {
        commit('setAsmList', { asmList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setAsmList(state, { asmList }) {
    state.asmList = asmList;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
