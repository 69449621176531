import _ from 'lodash';
import transactionComplainSerivce from '../../services/transactionComplainSerivce';
import { ComplainList, DeletedComplainList } from '../../model/complainList';

const state = {
  baseUrl: '/transaction-complain',
  complainList: [],
  deletedComplainList: [],
  downloadList: [],
  images: [],
  complain: null,
  pagination: {},
  loading: false
};

const actions = {
  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setTransactionComplain', { complainList: [], pagination: {} });

    commit('startRequest');
    transactionComplainSerivce
      .list({ query })
      .then(response => {
        commit('setTransactionComplain', { complainList: response.data.rows, pagination: response.data.pagination });
      })

      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDownloadTransactionComplain', { downloadList: [] });

    commit('startRequest');
    transactionComplainSerivce
      .list({ query })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Preparing your download, please wait...'
          },
          { root: true }
        );
        commit('setDownloadTransactionComplain', {
          downloadList: response.data.rows,
          pagination: response.data.pagination
        });
      })

      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deletedList({ dispatch, commit }, { type = 'archive', query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDeletedTransactionComplain', { deletedComplainList: [], pagination: {} });

    commit('startRequest');
    transactionComplainSerivce
      .deletedList({ type, query })
      .then(response => {
        commit('setDeletedTransactionComplain', {
          deletedComplainList: response.data.rows,
          pagination: response.data.pagination
        });
      })

      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  uploadImage({ dispatch, commit }, query = {}, router) {
    dispatch('alert/clear', {}, { root: true });
    const { file } = query;
    transactionComplainSerivce
      .getSignedUrl({ filename: file.name, filetype: file.type, file })
      .then(response => {
        transactionComplainSerivce
          .uploadImage({ url: response.data, file })
          .then(res => {
            dispatch('alert/success', {
              showType: 'toast',
              position: 'center',
              title: '',
              text: 'Image uploaded successfully'
            });
            commit('Nothing', res);
          })
          .catch(e => {
            commit('requestFailed');
            dispatch('common/handleServiceException', { e, router }, { root: true });
          });
        commit('setBillImages', { images: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setComplain', { complain: {} });
    transactionComplainSerivce
      .getOne({ id })
      .then(response => {
        commit('setComplain', { complain: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { complain, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    transactionComplainSerivce
      .postOne({ complain })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New complain List has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
          commit('setBillImages', { images: [] });
        }
      })
      .catch(e => {
        dispatch('alert/clear', {}, { root: true });
        dispatch(
          'alert/error',
          {
            showType: 'dialog',
            position: 'center',
            title: '',
            text: 'Transaction ID must be unique.'
          },
          { root: true }
        );
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  patchOne({ dispatch, commit }, { type = 'transactionComplain', complain, router, id, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    transactionComplainSerivce
      .patchOne({ type, complain, id })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: type === 'transactionComplain' ? 'Complain has been updated.' : 'Complain has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { type = 'list', id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    transactionComplainSerivce
      .deleteOne({ type, id })
      .then(_response => {
        dispatch('list', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: type === 'list' ? 'Complain has been deleted.' : 'Complain has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setTransactionComplain(state, { complainList, pagination }) {
    state.complainList = _.map(complainList, (complainList, index) => {
      const newComplainList = new ComplainList({
        index,
        id: complainList._id,
        storeName: complainList.storeName,
        storeId: complainList.storeId,
        customerName: complainList.customerName,
        customerPhone: complainList.customerPhone,
        smMobile: complainList.smMobile,
        transactionId: complainList.transactionId,
        complaintNumber: complainList.complaintNumber,
        transactionAmount: complainList.transactionAmount,
        transactionType: complainList.transactionType,
        transactionDate: complainList.transactionDate,
        updatedAt: complainList.updatedAt,
        currentStatus: complainList.currentStatus,
        customerRemarks: complainList.customerRemarks,
        approverRemarks: complainList.approverRemarks,
        inProgressRemarks: complainList.inProgressRemarks,
        createdAt: complainList.createdAt,
        createdByName: complainList.createdByName,
        images: complainList.images
      });
      return newComplainList;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadTransactionComplain(state, { downloadList }) {
    state.downloadList = _.map(downloadList, (downloadList, index) => {
      const newComplainList = new ComplainList({
        index,
        id: downloadList._id,
        storeName: downloadList.storeName,
        storeId: downloadList.storeId,
        customerName: downloadList.customerName,
        customerPhone: downloadList.customerPhone,
        smMobile: downloadList.smMobile,
        transactionId: downloadList.transactionId,
        complaintNumber: downloadList.complaintNumber,
        transactionAmount: downloadList.transactionAmount,
        transactionType: downloadList.transactionType,
        transactionDate: downloadList.transactionDate,
        updatedAt: downloadList.updatedAt,
        currentStatus: downloadList.currentStatus,
        customerRemarks: downloadList.customerRemarks,
        approverRemarks: downloadList.approverRemarks,
        inProgressRemarks: downloadList.inProgressRemarks,
        createdAt: downloadList.createdAt,
        createdByName: downloadList.createdByName,
        images: downloadList.images
      });
      return newComplainList;
    });
    // state.pagination = pagination;`
    state.loading = false;
  },
  setDeletedTransactionComplain(state, { deletedComplainList, pagination }) {
    state.deletedComplainList = _.map(deletedComplainList, (deletedComplainList, index) => {
      const newDeletedComplainList = new DeletedComplainList({
        index,
        id: deletedComplainList._id,
        storeName: deletedComplainList.storeName,
        storeId: deletedComplainList.storeId,
        customerName: deletedComplainList.customerName,
        customerPhone: deletedComplainList.customerPhone,
        smMobile: deletedComplainList.smMobile,
        transactionId: deletedComplainList.transactionId,
        complaintNumber: deletedComplainList.complaintNumber,
        transactionAmount: deletedComplainList.transactionAmount,
        transactionType: deletedComplainList.transactionType,
        transactionDate: deletedComplainList.transactionDate,
        updatedAt: deletedComplainList.updatedAt,
        currentStatus: deletedComplainList.currentStatus,
        customerRemarks: deletedComplainList.customerRemarks,
        approverRemarks: deletedComplainList.approverRemarks,
        createdAt: deletedComplainList.createdAt,
        createdByName: deletedComplainList.createdByName,
        archivedByName: deletedComplainList.archivedByName,
        images: deletedComplainList.images
      });
      return newDeletedComplainList;
    });
    state.loading = false;
  },

  setBillImages(state, { images }) {
    if (images.length > 0) {
      state.images.push(images.split('?')[0]);
    } else {
      state.images = [];
    }
    state.loading = false;
  },

  setComplain(state, { complain }) {
    state.complain = new ComplainList({
      id: complain._id,
      customerName: complain.customerName,
      customerRemarks: complain.customerRemarks,
      approverRemarks: complain.approverRemarks,
      inProgressRemarks: complain.inProgressRemarks,
      customerPhone: complain.customerPhone,
      smMobile: complain.smMobile,
      transactionId: complain.transactionId,
      complaintNumber: complain.complaintNumber,
      createdByName: complain.createdByName,
      currentStatus: complain.currentStatus,
      createdAt: complain.createdAt,
      transactionDate: complain.transactionDate,
      updatedAt: complain.updatedAt,
      storeId: complain.storeId,
      transactionAmount: complain.transactionAmount,
      transactionType: complain.transactionType,
      images: complain.images
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
