import _ from 'lodash';
import pettyCashService from '../../services/pettyCashService';
import {
  Budget,
  Expense,
  Transaction,
  BudgetIncrease,
  CashSnapshot,
  CashTransaction,
  CashSummary,
  SAPReport
} from '../../model/pettyCash';
import moment from 'moment';
const state = {
  baseUrl: '/petty-cash',
  budgetList: [],
  expenseList: [],
  budgetType: [],
  rulesList: [],
  expense: [],
  budget: [],
  loading: false,
  transactionList: [],
  summary: [],
  summaryBudget: [],
  increaseBudgetRequestList: [],
  pagination: {},
  bulkImages: [],
  cashSnapshotList: [],
  cashSnapshotTransactionList: [],
  storeCashTransactionList: [],
  otpGenerated: '',
  downloadExpenseList: [],
  pettyCashReportList: [],
  storeTransactionList: [],
  isAccountVerified: sessionStorage.getItem('isAccountVerified') ? sessionStorage.getItem('isAccountVerified') : null
};

const actions = {
  listBudget({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setBudgetList', { budgetList: [], pagination: {} });
    commit('startRequest');
    pettyCashService
      .listBudget({ query })
      .then(response => {
        commit('setBudgetList', { budgetList: response.data, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postBudget({ dispatch, commit }, { budget, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    pettyCashService
      .postBudget({ budget })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Budget has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: ''
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  bulkBudgetUpload({ dispatch, commit }, { list, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    pettyCashService
      .bulkBudgetUpload({ list })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Budget has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          dispatch('listBudget', { query: {} });
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setBudget', { budget: {} });
    pettyCashService
      .getOne({ id })
      .then(response => {
        commit('setBudget', { budget: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOneBudget({ dispatch, commit }, { id, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setBudget', { budget: {} });

    pettyCashService
      .deleteOneBudget({ id })
      .then(response => {
        commit('setBudget', { budget: response.data });
        if (redirectUrl !== '') {
          dispatch('listBudget', { query: {} });
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, budget, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    pettyCashService
      .patchOne({ id, newBudget: budget })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Budget has been live.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          dispatch('listBudget', { query: {} });
        }
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: ''
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listExpense({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setExpenseList', { expenseList: [], pagination: {} });
    commit('startRequest');
    pettyCashService
      .listExpense({ query })
      .then(response => {
        commit('setExpenseList', { expenseList: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listBudgetType({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setBudgetType', { budgetType: [], pagination: {} });
    commit('startRequest');
    pettyCashService
      .listBudgetType()
      .then(response => {
        commit('setBudgetType', { budgetType: response.data, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  postExpense({ dispatch, commit }, { expense, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    pettyCashService
      .postExpense({ expense })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Expense has been added.'
          },
          { root: true }
        );
        if (redirectUrl !== '') {
          router.push(redirectUrl);
          commit('setBillImages', { bulkImages: [] });
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOneExpense({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setExpense', { expense: {} });
    pettyCashService
      .getOneExpense({ id })
      .then(response => {
        commit('setExpense', { expense: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOneExpense({ dispatch, commit }, { id, expense, router, redirectUrl = '', query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    pettyCashService
      .patchOneExpense({ id, newExpense: expense })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Expense has been updated.'
          },
          { root: true }
        );
        if (redirectUrl !== '') {
          router.push(redirectUrl);
        } else {
          dispatch('listExpense', { query });
          dispatch('listCashSnapshot', { query: {} });
        }
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: ''
          },
          { root: true }
        );
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listRules({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setRulesList', { rulesList: [], pagination: {} });
    commit('startRequest');
    pettyCashService
      .listRules()
      .then(response => {
        commit('setRulesList', { rulesList: response.data, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  uploadImage({ dispatch, commit }, query = {}, router) {
    dispatch('alert/clear', {}, { root: true });
    const { file } = query;
    pettyCashService
      .getSignedUrl({ filename: file.name, filetype: file.type, file })
      .then(response => {
        pettyCashService
          .uploadImage({ url: response.data, file })
          .then(res => {
            dispatch('alert/success', {
              showType: 'toast',
              position: 'center',
              title: '',
              text: 'Image uploaded successfully'
            });
            commit('Nothing', res);
          })
          .catch(e => {
            commit('requestFailed');
            dispatch('common/handleServiceException', { e, router }, { root: true });
          });
        commit('setBillImages', { bulkImages: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listTransaction({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setTransactionList', { transactionList: [], pagination: {} });
    commit('startRequest');
    pettyCashService
      .listTransaction()
      .then(response => {
        commit('setTransactionList', { transactionList: response.data, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  getSummaryForStore({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setSummary', { summary: [] });

    pettyCashService
      .getSummary({ id })
      .then(response => {
        commit('setSummary', { summary: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getSummaryForBudget({ dispatch, commit }, { router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setBudgetSummary', { summaryBudget: [] });

    pettyCashService
      .getBudgetSummary({})
      .then(response => {
        commit('setBudgetSummary', { summaryBudget: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listBudgetIncreaseRequest({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setIncreaseBudgetList', { increaseBudgetRequestList: [], pagination: {} });
    commit('startRequest');
    pettyCashService
      .listBudgetIncreaseRequest({ query })
      .then(response => {
        commit('setIncreaseBudgetList', {
          increaseBudgetRequestList: response.data.rows,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  postBudgetIncreaseRequest({ dispatch, commit }, { request, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    pettyCashService
      .postBudgetIncreaseRequest({ request })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Request has been send.'
          },
          { root: true }
        );
        if (redirectUrl !== '') {
          dispatch('listBudgetIncreaseRequest', { query: {} });
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchMakeAllBudgetLive({ dispatch, commit }, { list, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    pettyCashService
      .patchMakeAllBudgetLive({ list })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'All Budget has been updated successfully'
          },
          { root: true }
        );
        if (redirectUrl !== '') {
          dispatch('listBudget', { query: {} });
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchBudgetIncrease({ dispatch, commit }, { id, request, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    pettyCashService
      .patchBudgetIncrease({ id, request })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Budget Request has been updated.'
          },
          { root: true }
        );
        if (redirectUrl !== '') {
          dispatch('listBudgetIncreaseRequest', { query: {} });
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listCashSnapshot({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setCashSnapshotList', { cashSnapshotList: [], pagination: {} });
    commit('startRequest');
    pettyCashService
      .listCashSnapshot({ query })
      .then(response => {
        commit('setCashSnapshotList', { cashSnapshotList: response.data, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postCashSnapshot({ dispatch, commit }, { request, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    pettyCashService
      .postCashSnapshot({ request })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Cash Snapshot has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postAddCash({ dispatch, commit }, { addition, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    pettyCashService
      .postAddCash({ addition })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Cash has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          dispatch('listCashSnapshot', { query: {} });
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listCashSnapshot_Transaction({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setCashSnapshotTransactionList', { cashSnapshotTransactionList: [], pagination: {} });
    commit('startRequest');
    pettyCashService
      .listCashSnapshot_Transaction({ query })
      .then(response => {
        commit('setCashSnapshotTransactionList', {
          cashSnapshotTransactionList: response.data.rows,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  storeWiseCashTransaction({ dispatch, commit }, { query, storeId, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setStoreCashTransactionList', { storeCashTransactionList: [], pagination: {} });
    commit('startRequest');
    pettyCashService
      .storeWiseCashTransaction({ query, storeId })
      .then(response => {
        commit('setStoreCashTransactionList', {
          storeCashTransactionList: response.data.rows,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  bulkCashUpload({ dispatch, commit }, { list, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    pettyCashService
      .bulkCashUpload({ list })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Cash has been added.'
          },
          { root: true }
        );
        if (redirectUrl !== '') {
          dispatch('listCashSnapshot', { query: {} });
        }
      })
      .catch(e => {
        let errorMessage = '';
        if (_.isEmpty(e.response.data.data)) {
          errorMessage = 'Wrong mapping of store with Petty Cash Gl Code';
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: 'Please correctly map store code with petty cash gl code'
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  bulkCreateAccount({ dispatch, commit }, { list, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    pettyCashService
      .bulkCreateAccount({ list })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Cash has been added.'
          },
          { root: true }
        );
        if (redirectUrl !== '') {
          dispatch('listCashSnapshot', { query: {} });
        }
      })
      .catch(e => {
        commit('requestFailed');

        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  generateOtp({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setOtp', { otpGenerated: '' });
    commit('startRequest');

    pettyCashService
      .generateOtp()
      .then(response => {
        if (response.status === 200) {
          commit('setOtp', { otpGenerated: 'success' });
        }
      })
      .catch(e => {
        commit('setOtp', { otpGenerated: 'Failure' });
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: 'OTP not generated , Please try again',
            type: 'error',
            showType: 'dialog',
            text: '',
            timer: 2000
          },
          { root: true }
        );
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  verifyOtp({ dispatch, commit }, { otp }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setAccountVerified', { isAccountVerified: '' });
    commit('startRequest');

    pettyCashService
      .verifyOtp({ otp })
      .then(response => {
        if (response.status === 200) {
          commit('setAccountVerified', { isAccountVerified: 'Verified' });
          dispatch(
            'alert/info',
            {
              showType: 'toast',
              position: 'center',
              type: 'success',
              title: 'OTP verified Successfully'
            },
            { root: true }
          );
        }
      })
      .catch(e => {
        commit('setAccountVerified', { isAccountVerified: 'Failure' });
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: 'Invalid OTP! Please enter the correct OTP',
            type: 'error',
            showType: 'dialog',
            text: ''
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  patchMakeAllExpenseLive({ dispatch, commit }, { list, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    pettyCashService
      .patchMakeAllExpenseLive({ list })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'All Expenses has been Approved successfully'
          },
          { root: true }
        );

        dispatch('listExpense', { query: {} });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: 'Something went wrong, Please try again',
            type: 'error',
            showType: 'dialog',
            text: '',
            timer: 2000
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchEditExpenseList({ dispatch, commit }, { list, query, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    pettyCashService
      .patchEditExpenseList({ list })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'All Expenses has been Edit successfully'
          },
          { root: true }
        );

        dispatch('listExpense', { query: query });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: 'Something went wrong, Please try again',
            type: 'error',
            showType: 'dialog',
            text: '',
            timer: 2000
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  async download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setDownloadExpenseList', { downloadExpenseList: [] });
    pettyCashService
      .listExpense({ query })
      .then(response => {
        commit('setDownloadExpenseList', { downloadExpenseList: response.data.rows });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  async downloadStoreTransactionList({ dispatch, commit }, { query = {}, storeId }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setStoreTransactionList', { storeTransactionList: [] });
    pettyCashService
      .storeWiseCashTransaction({ query, storeId })
      .then(response => {
        commit('setStoreTransactionList', { storeTransactionList: response.data.rows });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  pettyCashReport({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setPettyCashReportList', { pettyCashReportList: [], pagination: {} });
    commit('startRequest');
    pettyCashService
      .pettyCashReport({ query })
      .then(response => {
        commit('setPettyCashReportList', { pettyCashReportList: response.data, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  updateGlNameFromStore({ _, commit }, { row, glName }) {
    commit('updateGlName', { row, glName });
  },
  updateExpenseGlCodeFromStore({ _, commit }, { row, expenseGlCode }) {
    commit('updateExpenseGlCode', { row, expenseGlCode });
  }
};

const getters = {
  userData(_, getters, root, rootGetters) {
    return rootGetters['auth/getUserData'];
  },
  ifSpencerAdmin(_, getters) {
    const userData = getters.userData;
    const isAdmin = userData?.roles?.filter(role => role.name === 'ADMIN');
    // role.name === 'ADMIN' ||
    if (isAdmin !== null && isAdmin !== undefined && isAdmin.length > 0) {
      return true;
    }
    return false;
  },
  ifAdmin(_, getters) {
    const userData = getters.userData;
    const isAdmin = userData?.roles?.filter(role => role.name === 'FINANCE_ADMIN');
    // role.name === 'ADMIN' ||
    if (isAdmin !== null && isAdmin !== undefined && isAdmin.length > 0) {
      return true;
    }
    return false;
  },
  ifAccountant(_, getters) {
    const userData = getters.userData;
    const isAccountant = userData?.roles?.filter(role => role.name === 'ACCOUNTANT');
    if (isAccountant !== null && isAccountant !== undefined && isAccountant.length > 0) {
      return true;
    }
    return false;
  },
  ifPettyCashTeam(_, getters) {
    const userData = getters.userData;
    const isAccountant = userData?.roles?.filter(role => role.name === 'ACCOUNTANT' || role.name === 'FINANCE_ADMIN');
    if (isAccountant !== null && isAccountant !== undefined && isAccountant.length > 0) {
      return true;
    }
    return false;
  },
  ifSM(_, getters) {
    const userData = getters.userData;
    const sM = userData?.roles?.filter(role => role.name === 'STORE_SALES_MANAGER');
    if (sM !== null && sM !== undefined && sM.length > 0) {
      return true;
    }
    return false;
  },
  ifRSM(_, getters) {
    const userData = getters.userData;
    const rSM = userData?.roles?.filter(role => role.name === 'REGIONAL_SALES_MANAGER');
    if (rSM !== null && rSM !== undefined && rSM.length > 0) {
      return true;
    }
    return false;
  },
  ifASM(_, getters) {
    const userData = getters.userData;
    const rSM = userData?.roles?.filter(role => role.name === 'AREA_SALES_MANAGER');
    if (rSM !== null && rSM !== undefined && rSM.length > 0) {
      return true;
    }
    return false;
  },
  isSmAsmRsm(state, getters) {
    const userData = getters.userData;
    const isSmAsmRsm = userData?.roles?.filter(
      role =>
        role.name === 'STORE_SALES_MANAGER' ||
        role.name === 'REGIONAL_SALES_MANAGER' ||
        role.name === 'AREA_SALES_MANAGER'
    );
    if (isSmAsmRsm !== null && isSmAsmRsm !== undefined && isSmAsmRsm.length > 0) {
      return true;
    }
    return false;
  }
};

const mutations = {
  setPettyCashReportList(state, { pettyCashReportList }) {
    state.pettyCashReportList = pettyCashReportList
      .flatMap(item => item.items)
      .map((item, index) => {
        const rowNum = Math.floor(index / 2) + 1;
        const newList = new SAPReport({
          rowNum: rowNum - 1,

          accountNumber: item.accountNumber,
          amount: item.amount,
          assignmentField: item.assignmentField,
          businessPlace: item.businessPlace,
          companyCode: item.companyCode,
          companyName: item.companyName,
          costCenter: item.costCenter,
          createdAt: item.createdAt,
          currency: item.currency,
          docuHeadText: item.docuHeadText,
          documentDate: item.documentDate,

          documentType: item.documentType,
          dueDate: item.dueDate,
          postingDate: item.postingDate,
          postingKey: item.postingKey,
          profitCenter: item.profitCenter,
          referenceNumber: item.referenceNumber,

          sectionCode: item.sectionCode,
          site: item.site,
          specialGlIndicator: item.specialGlIndicator,
          text: item.text,
          updatedAt: item.updateGlNameFromStore,
          id: item._id,
          documentNumber: item.documentNumber,
          revMessage: item.revMessage
        });
        return newList;
      });
    state.loading = false;
  },
  setStoreTransactionList(state, { storeTransactionList }) {
    state.storeTransactionList = _.map(storeTransactionList, (cashTransaction, index) => {
      const newList = new CashTransaction({
        index,
        id: cashTransaction._id, // eslint-disable-line
        storeId: cashTransaction.storeId,
        amount: cashTransaction.amount,
        balanceLeft: cashTransaction.balanceLeft,
        oldBalance: cashTransaction.oldBalance,
        transactionType: cashTransaction.transactionType,
        expenseGlCode: cashTransaction.expenseGlCode,
        glName: cashTransaction.glName,
        pattyCashSnapshotId: cashTransaction.pattyCashSnapshotId,
        type: cashTransaction.type,
        pattyCashStoreGl: cashTransaction.pattyCashStoreGl,
        createdAt: cashTransaction.createdAt,
        updatedAt: cashTransaction.updatedAt,
        createdBy: cashTransaction?.createdBy
      });
      return newList;
    });
    state.loading = false;
  },
  setDownloadExpenseList(state, { downloadExpenseList }) {
    state.downloadExpenseList = _.map(downloadExpenseList, (expense, index) => {
      const newExpenseList = new Expense({
        index,
        id: expense._id, // eslint-disable-line
        storeId: expense.storeId,
        billDate: expense.billDate,
        approvedBy: expense.approvedBy ? expense.approvedBy : {},
        approvedByHO: expense.approvedByHO?.name,
        costCenter: expense.costCenter,
        amount: expense.amount,
        companyCode: expense.companyCode,
        expenseGlCode: expense.expenseGlCode,
        pattyCashStoreGl: expense.pattyCashStoreGl,
        isApprovedByHO: expense.isApprovedByHO,
        isApproved: expense.isApproved,
        companyName: expense.companyName,
        createdAt: expense.createdAt,
        statusRemarks: expense.statusRemarks,
        isEdited: expense.isEdited,
        updatedAt: expense.updatedAt,
        postingDate: expense.postingDate,
        recipient: expense.recipient,
        referenceNumber: expense.referenceNumber,
        status: expense.status,
        text: expense.text,
        glName: expense.glName,
        bulkReciptImage: expense.bulkReciptImage,
        createdBy: expense.createdBy
        // ruleApplied: expense.ruleApplied
      });
      return newExpenseList;
    });
    state.loading = false;
  },
  updateGlName(state, payload) {
    const { row, glName } = payload;
    const listToUpdate = state.expenseList.find(item => item.id === row.id);
    if (listToUpdate) {
      listToUpdate.glName = glName;
    }
  },
  updateExpenseGlCode(state, payload) {
    const { row, expenseGlCode } = payload;
    const listToUpdate = state.expenseList.find(item => item.id === row.id);
    if (listToUpdate) {
      listToUpdate.expenseGlCode = expenseGlCode;
    }
  },
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setBudgetList(state, { budgetList }) {
    state.budgetList = _.map(budgetList, (budget, index) => {
      const newBudgetList = new Budget({
        index,
        id: budget._id, // eslint-disable-line
        storeId: budget.storeId,
        budgetAmount: budget.budgetAmount,
        costCenter: budget.costCenter,
        companyName: budget.companyName,
        pattyCashStoreGl: budget.pattyCashStoreGl,
        storeType: budget.storeType,
        createdAt: budget.createdAt,
        updatedAt: budget.updatedAt,
        isMakeLive: budget.isMakeLive,
        createdBy: budget?.createdBy?.name,
        month: moment(budget?.validFrom).format('MMMM'),
        validTo: budget?.validTo,
        validFrom: budget?.validFrom
      });
      return newBudgetList;
    });
    state.loading = false;
  },
  setExpenseList(state, { expenseList, pagination }) {
    state.expenseList = _.map(expenseList, (expense, index) => {
      const newExpenseList = new Expense({
        index,
        id: expense._id, // eslint-disable-line
        storeId: expense.storeId,
        billDate: expense.billDate,
        approvedBy: expense.approvedBy ? expense.approvedBy : {},
        approvedByHO: expense.approvedByHO?.name,
        costCenter: expense.costCenter,
        amount: expense.amount,
        companyCode: expense.companyCode,
        expenseGlCode: expense.expenseGlCode,
        pattyCashStoreGl: expense.pattyCashStoreGl,
        isApprovedByHO: expense.isApprovedByHO,
        isApproved: expense.isApproved,
        companyName: expense.companyName,
        createdAt: expense.createdAt,
        statusRemarks: expense.statusRemarks,
        isEdited: expense.isEdited,
        updatedAt: expense.updatedAt,
        postingDate: expense.postingDate,
        recipient: expense.recipient,
        referenceNumber: expense.referenceNumber,
        status: expense.status,
        text: expense.text,
        glName: expense.glName,
        bulkReciptImage: expense.bulkReciptImage,
        createdBy: expense.createdBy
        // ruleApplied: expense.ruleApplied
      });
      return newExpenseList;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setBudget(state, { budget }) {
    state.budget = budget;
    state.loading = false;
  },
  setOtp(state, { otpGenerated }) {
    state.otpGenerated = otpGenerated;
    state.loading = false;
  },
  setAccountVerified(state, { isAccountVerified }) {
    function encrypt(text, key) {
      let encryptedText = '';
      for (let i = 0; i < text?.length; i++) {
        const charCode = text?.charCodeAt(i) ^ key.charCodeAt(i % key.length);
        encryptedText += String.fromCharCode(charCode);
      }
      return encryptedText;
    }
    state.isAccountVerified = isAccountVerified;
    const encrypted = encrypt(isAccountVerified, 'secret key 123');
    sessionStorage.setItem('isAccountVerified', encrypted);
    state.loading = false;
  },
  setStoreCashTransactionList(state, { storeCashTransactionList, pagination }) {
    state.storeCashTransactionList = _.map(storeCashTransactionList, (cashTransaction, index) => {
      const newBudgetList = new CashTransaction({
        index,
        id: cashTransaction._id, // eslint-disable-line
        storeId: cashTransaction.storeId,
        amount: cashTransaction.amount,
        balanceLeft: cashTransaction.balanceLeft,
        oldBalance: cashTransaction.oldBalance,
        transactionType: cashTransaction.transactionType,
        expenseGlCode: cashTransaction.expenseGlCode,
        glName: cashTransaction.glName,
        pattyCashSnapshotId: cashTransaction.pattyCashSnapshotId,
        type: cashTransaction.type,
        pattyCashStoreGl: cashTransaction.pattyCashStoreGl,
        createdAt: cashTransaction.createdAt,
        updatedAt: cashTransaction.updatedAt,
        createdBy: cashTransaction?.createdBy
      });
      return newBudgetList;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setCashSnapshotList(state, { cashSnapshotList }) {
    state.cashSnapshotList = _.map(cashSnapshotList, (cashList, index) => {
      const newBudgetList = new CashSnapshot({
        index,
        id: cashList._id, // eslint-disable-line
        storeId: cashList.storeId,
        currentBalance: cashList.currentBalance,
        startingBalance: cashList.startingBalance,
        monthlyOpeningBalance: cashList.monthlyOpeningBalance,
        companyName: cashList.companyName,
        pattyCashStoreGl: cashList.pattyCashStoreGl,
        createdAt: cashList.createdAt,
        updatedAt: cashList.updatedAt,
        monthlyOpeningBalanceDate: cashList.monthlyOpeningBalanceDate,
        startingBalanceDate: cashList.startingBalanceDate,
        createdBy: cashList?.createdBy
      });
      return newBudgetList;
    });
    state.loading = false;
  },
  setCashSnapshotTransactionList(state, { cashSnapshotTransactionList, pagination }) {
    state.cashSnapshotTransactionList = _.map(cashSnapshotTransactionList, (cashTransaction, index) => {
      const newBudgetList = new CashTransaction({
        index,
        id: cashTransaction._id, // eslint-disable-line
        storeId: cashTransaction.storeId,
        amount: cashTransaction.amount,
        balanceLeft: cashTransaction.balanceLeft,
        oldBalance: cashTransaction.oldBalance,
        transactionType: cashTransaction.transactionType,
        expenseGlCode: cashTransaction.expenseGlCode,
        glName: cashTransaction.glName,
        pattyCashSnapshotId: cashTransaction.pattyCashSnapshotId,
        type: cashTransaction.type,
        pattyCashStoreGl: cashTransaction.pattyCashStoreGl,
        createdAt: cashTransaction.createdAt,
        updatedAt: cashTransaction.updatedAt,
        createdBy: cashTransaction?.createdBy
      });
      return newBudgetList;
    });
    state.loading = false;
    state.pagination = pagination;
  },
  setBillImages(state, { bulkImages }) {
    if (bulkImages.length > 0) {
      state.bulkImages.push(bulkImages.split('?')[0]);
    } else {
      state.bulkImages = [];
    }
    state.loading = false;
  },
  setIncreaseBudgetList(state, { increaseBudgetRequestList, pagination }) {
    state.increaseBudgetRequestList = _.map(increaseBudgetRequestList, (list, index) => {
      const newExpenseList = new BudgetIncrease({
        index,
        id: list._id, // eslint-disable-line
        storeId: list.storeId,
        amount: list.amount,
        createdBy: list.createdBy?.name,
        pattyCashStoreGl: list.pattyCashStoreGl,
        statusRemarks: list.statusRemarks,
        approvedByRSM: list?.approvedByRSM?.name,
        createdAt: list.createdAt,
        reasonText: list.reasonText,
        status: list.status,
        isApprovedByRSM: list.isApprovedByRSM,
        updatedAt: list.updatedAt,
        isApproved: list.isApproved
      });
      return newExpenseList;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setSummary(state, { summary }) {
    state.summary = new CashSummary({
      totalBudget: summary.totalBudget,
      totalBudgetBalance: summary.totalBudgetBalance,
      totalPattyCashBalance: summary.totalPattyCashBalance,
      totalUsedBudget: summary.totalUsedBudget,
      totalUsedPattyCash: summary.totalUsedPattyCash
    });
    state.loading = false;
  },
  setBudgetSummary(state, { summaryBudget }) {
    state.summaryBudget = summaryBudget.map((i, index) => ({
      ...i,
      rowNum: index + 1
    }));
    state.loading = false;
  },
  setExpense(state, { expense }) {
    state.expense = expense;
    state.loading = false;
  },
  setBudgetType(state, { budgetType }) {
    state.budgetType = budgetType;
    state.loading = false;
  },
  setRulesList(state, { rulesList }) {
    state.rulesList = rulesList;
    state.loading = false;
  },
  setTransactionList(state, { transactionList }) {
    state.transactionList = _.map(transactionList, (transaction, index) => {
      const newBudgetList = new Transaction({
        index,
        id: transaction._id, // eslint-disable-line
        storeId: transaction.storeId,
        amount: transaction.amount,
        balanceLeft: transaction.balanceLeft,
        oldBalance: transaction.oldBalance,
        status: transaction.creditedToStore !== undefined ? 'Credited' : 'Debited',
        createdAt: transaction.createdAt,
        updatedAt: transaction.updatedAt,
        budgetType: transaction.budgetType,
        createdBy: transaction.createdBy
      });

      return newBudgetList;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
