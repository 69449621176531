import _ from 'lodash';
import dashboardService from '@/services/dashboardService';
import Dashboard from '../../model/dashboard';

const state = {
  baseUrl: '/dashboard',
  dashboardChart: [],
  dashboardData: {},
  loading: false
};

const actions = {
  chartListFirst({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDashboardChart', { dashboardChart: [] });

    dashboardService
      .list({ query })
      .then(response => {
        commit('setDashboardChart', { dashboardChart: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  mobileList({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDashboardData', { dashboardData: {} });

    dashboardService
      .mobileList({ query })
      .then(response => {
        commit('setDashboardData', { dashboardData: response.data });
        commit('finishRequest');
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setDashboardChart(state, { dashboardChart }) {
    let row = 1;
    state.dashboardChart = _.map(dashboardChart, dashboard => {
      const newDashboard = new Dashboard({
        row,
        storeid: dashboard.storeid,
        storename: dashboard.storename,
        cluster: dashboard.cluster,
        region: dashboard.region,
        count: dashboard.count,
        submittedCount: dashboard.submittedCount,
        pendingCount: dashboard.pendingCount
      });
      row += 1;
      return newDashboard;
    });
  },
  setDashboardData(state, { dashboardData }) {
    state.dashboardData = dashboardData;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
