import moment from 'moment';
export default class GRNGap {
  constructor({ grnEntryTime, storeId, createdAt, submittedBy, vehicleEntryTime, grnGapTime }) {
    this.storeId = storeId || '';
    this.submittedBy = submittedBy || '-';
    this.grnEntryTime = grnEntryTime || '-';
    this.vehicleEntryTime = vehicleEntryTime || 'N/A';
    this.grnGapTime = grnGapTime || 'N/A';
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm: A') : null;
  }
}
